<script>
export default {
  name: 'SimpleReadOnlyField',

  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    labelTooltip: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: [String, Number],
      required: false,
      default: '',
    },
    valueTooltip: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<template>
  <el-row :gutter="8"
    class="row">
    <!-- Label -->
    <el-col :span="12"
      class="column">
      <slot name="label">
        <template v-if="labelTooltip">
          <el-tooltip placement="top"
            :content="labelTooltip"
            :open-delay="500">
            <span class="tooltip-label">
              {{ label }}:
            </span>
          </el-tooltip>
        </template>
        <template v-else>
          {{ label }}:
        </template>
      </slot>
    </el-col>
    <!-- Value -->
    <el-col :span="12"
      class="column">
      <slot>
        <template v-if="valueTooltip">
          <el-tooltip placement="top"
            :content="valueTooltip"
            :open-delay="500">
            <span>{{ value }}</span>
          </el-tooltip>
        </template>
        <template v-else>
          {{ value }}
        </template>
      </slot>
    </el-col>
  </el-row>
</template>

<style lang="scss" scoped>
.row {
  color: var(--color-text-regular);
  font-weight: 300;
  padding: 0.5rem;
  min-width: 500px;

  .column {
    word-break: break-word;

    &:first-of-type {
      font-weight: 500;
      text-align: right;
    }
  }
}

.tooltip-label {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 3px;
}
</style>
