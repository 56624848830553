<template>
  <div :data-product-type="portObj.productType">
    <!-- Port Terms -->
    <port-term v-if="showTermPanel"
      :visible.sync="showTermPanel"
      :port="portObj" />

    <!-- Name -->
    <el-form-item prop="productName"
      :label="$t('general.type-name', { type: $t(isMcr ? 'productNames.mcr' : 'productNames.port') })"
      label-width="250px">
      <el-input :value="form.productName"
        name="productName"
        :data-demo="isMcr ? 'Example MCR' : 'Example Port'"
        @input="handleFormInputChange({ value: $event }, 'productName')" />
    </el-form-item>
    <!-- END -->

    <!-- MCR Rate Limit -->
    <mu-form-group v-if="isMcr"
      :label="$t('ports.mcr-rate-limit')"
      label-width="238px"
      label-align="right"
      required
      :class="'mb-0'">
      <mu-rate-limit-input :default-value="form.rateLimit"
        :disabled="true"
        required
        data-name="rateLimit" />
    </mu-form-group>
    <!-- END -->

    <!-- BGP State -->
    <el-form-item v-if="isMcr"
      prop="bgpShutdownDefault"
      label-width="250px">
      <template #label>
        {{ $t('services.initial-bgp-state') }}
        <el-tooltip placement="top"
          :content="$t('ports.bgp-default-state-tooltip')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info padding0005"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-radio-group :value="form.bgpShutdownDefault"
        @input="handleFormInputChange({ value: $event }, 'bgpShutdownDefault')">
        <el-radio-button :label="false"
          name="bgpEnabled">
          {{ $t('general.enabled') }}
        </el-radio-button>
        <el-radio-button :label="true"
          name="bgpShutDown">
          {{ $t('general.shut-down') }}
        </el-radio-button>
      </el-radio-group>
    </el-form-item>
    <!-- END -->

    <!-- Partner Deals -->
    <el-form-item v-if="showDeals"
      prop="deals"
      :label="$t('general.partner-deals')"
      label-width="250px">
      <deal-select :form-data="form"
        :deals="deals"
        :service-data="portObj"
        @updateDealUid="handleFormInputChange({ value: $event }, 'dealUid')" />
    </el-form-item>

    <!-- Invoice -->
    <el-form-item v-if="editable || form.costCentre"
      prop="costCentre"
      label-width="250px">
      <template #label>
        {{ $t('services.invoice-reference') }}
        <el-tooltip placement="top"
          :open-delay="500">
          <template #content>
            <p class="tooltip-p">
              {{ $t('services.invoice-reference-explanation') }}
            </p>
            <p v-if="!disabledFeatures.knowledgeBase"
              class="tooltip-p">
              {{ $t('general.details-help') }}
              <a :href="isMcr ? 'https://docs.megaport.com/mcr/creating-mcr/' : 'https://docs.megaport.com/connections/creating-port/'"
                target="_blank">
                <el-button size="mini">{{ $t('general.documentation') }}</el-button>
              </a>
            </p>
          </template>
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-input :value="form.costCentre"
        name="costCentre"
        @input="handleFormInputChange({ value: $event }, 'costCentre')" />
    </el-form-item>
    <!-- END -->

    <!-- This is a temporary message letting users know marketplace visibility settings have moved -->
    <!-- Megaport Marketplace Visibility -->
    <div class="el-form-item marketplace-form-item">
      <span class="el-form-item__label">
        {{ $t('productNames.marketplace') }}
      </span>
      <div class="disclaimer">
        <i18n path="marketplace.publish-disclaimer-link"
          tag="p"
          class="m-0">
          <template #link>
            <router-link to="/marketplace/profile?tab=services">
              {{ $t('page-titles.marketplace-profile') }}.
            </router-link>
          </template>
        </i18n>
      </div>
    </div>
    <!-- END -->

    <!-- Contract Terms / Subscription -->
    <template v-if="portObj.contractEndDate || portObj.contractTermMonths">
      <el-form-item :label="$t(isPartnerVantage ? 'partner-vantage.subscription' : 'ports.terms')"
        label-width="250px">
        <template v-if="showTerm">
          <p v-if="portObj.contractEndDate && portObj.provisioningStatus === G_PROVISIONING_LIVE"
            class="m-0">
            <strong class="pr-1">{{ $t('general.end-date') }}: </strong>
            {{ formatDate(portObj.contractEndDate) }}
          </p>
          <p v-else
            class="m-0">
            <strong class="pr-1">{{ $t('general.end-date') }}: </strong>
            {{ $t('ports.end-date-display', { productName }) }}
          </p>
        </template>
        <p v-if="portObj.contractTermMonths"
          class="m-0">
          <strong class="pr-1">{{ $t('general.term') }}: </strong>
          {{ termDisplay }}
        </p>
        <template v-if="canChangePortTerm">
          <el-button class="mr-1"
            data-name="change-term"
            @click="showTermPanel = true">
            {{ $t(isPartnerVantage ? 'partner-vantage.change-subscription' : 'ports.change-term') }}
          </el-button>
          <a v-if="!disabledFeatures.knowledgeBase && !isPartnerVantage"
            :href="docsLink"
            target="_blank"
            rel="noopener"
            class="d-block my-0">
            {{ $t('ports.term-info', { productName }) }}
          </a>
        </template>
      </el-form-item>
    </template>
  </div>
</template>

<script>
// External Tools
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
// Internal Tools
import { convertProductType } from '@/helpers'
// Components
import PortTermComponent from '@/components/PortTerm.vue'
import DealSelect from '@/components/DealSelect.vue'
// Globals
import { MEGAPORT_ASN } from '@/Globals.js'

export default {
  name: 'EditPortDetails',

  components: {
    'port-term': PortTermComponent,
    'deal-select': DealSelect,
  },

  inject: ['isFeatureEnabled'],

  props: {
    portObj: {
      type: Object,
      required: true,
    },
    deals: {
      type: Array,
      required: true,
    },
    speedFix: {
      type: Function,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    disabledFeatures: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showTermPanel: false,
    }
  },

  computed: {
    ...mapGetters('Auth', ['isManagedAccount', 'hasAuth', 'isPartnerVantage']),

    isMcr() {
      return this.portObj.productType === this.G_PRODUCT_TYPE_MCR2
    },
    mcrAsn() {
      return this.portObj?.resources?.virtual_router?.mcrAsn || MEGAPORT_ASN
    },
    mcrMinSpeed() {
      if (!this.isMcr) return 0

      const vxcSpeeds = this.portObj.associatedVxcs.map(service => service.rateLimit)

      return Math.max(...vxcSpeeds) || 0
    },
    serviceStatusIsTermReady() {
      const relevantProvisioningStatuses = [
        this.G_PROVISIONING_CANCELLED,
        this.G_PROVISIONING_CANCELLED_PARENT,
        this.G_PROVISIONING_CONFIGURED,
        this.G_PROVISIONING_DEPLOYABLE,
        this.G_PROVISIONING_LIVE,
      ]

      return relevantProvisioningStatuses.includes(this.portObj.provisioningStatus)
    },
    showTerm() {
      if (!this.serviceStatusIsTermReady) return false

      return this.portObj.contractTermMonths > 1
    },
    termDisplay() {
      if (!this.serviceStatusIsTermReady) {
        return this.$t('ports.contract-terms')
      }

      if (this.showTerm) {
        return this.$tc('general.count-months', this.portObj.contractTermMonths, { count: this.portObj.contractTermMonths })
      }

      if (this.isMcr) {
        return this.$t(this.isPartnerVantage ? 'general.na' : 'port-term.no-minimum-term')
      }

      return this.$t('ports.monthly')
    },
    canChangePortTerm() {
      return this.serviceStatusIsTermReady ? this.canOrder : false
    },
    canOrder() {
      return this.hasAuth('place_order') && !this.portObj.adminLocked && !this.portObj.locked
    },
    showDeals() {
      // Check if the relevant flags are not present
      if (!this.isFeatureEnabled('VANTAGE_PARTNER') && !this.isFeatureEnabled('VANTAGE_MANAGED_ACCOUNT')) {
        return false
      }

      // Check if secondary LAG
      if (this.portObj.aggregationId && !this.portObj.lagPrimary) {
        return false
      }

      // Check if managed account
      if (this.isManagedAccount) {
        return true
      }

      return false
    },
    productName() {
      return convertProductType(this.portObj.productType)
    },
    docsLink() {
      const urlProductTypeSubstring = this.isMcr ? 'mcr' : 'port'

      return `https://docs.megaport.com/finance/${urlProductTypeSubstring}-pricing/`
    },
  },

  methods: {
    formatDate(date) {
      return DateTime.fromMillis(date).toFormat('y-LL-dd')
    },
    handleFormInputChange({ value }, key) {
      this.$emit('update', { key, value })
    },
  },
}
</script>

<style lang="scss" scoped>
.mp-form-group.inline::v-deep label {
  padding-right: 12px !important;
}

// Only used for the temporary marketplace migration message
.marketplace-form-item {
  .el-form-item__label {
    width: 250px;
  }
  .disclaimer {
    margin-left: 250px;
    background-color: var(--color-white);
    border: 1px solid var(--color-warning);
    border-radius: var(--border-radius-base);
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--color-text-regular);
    margin-bottom: 1.3rem;

    p {
      max-width: 400px;
    }
  }
}
</style>
