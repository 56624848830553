<template>
  <div>
    <div class="header"
      :class="{ 'sticky-top': !layout.integratedHeader }">
      <h3 class="text-align-center">
        {{ $t('general.type-details', { type: $t(isMcr ? 'productNames.mcr' : 'productNames.port') }) }}
      </h3>
      <div class="steps wl-steps"
        role="presentation">
        <el-steps :active="tabIdx"
          :align-center="true"
          class="port-details m-auto mt-1-3 max-width-500px">
          <el-step v-for="(step, index) in allTabs"
            :key="index"
            :title="step.title"
            class="cursor-pointer"
            :icon="step.icon"
            :status="index === tabIdx ? 'process' : 'finish'"
            @click.native="tabIdx = index" />
        </el-steps>
      </div>
    </div>

    <div class="p-20px pb-50px">
      <info-box v-if="!editable"
        :body="nonEditableReason"
        variant="warning" />

      <end-details-card class="port-summary"
        :left-icon-id="isMcr ? 'MCR' : 'PORT'"
        :detail-lines="detailLines"
        :right-image-src="locationSelect ? `https://media.megaport.com/datacentre/${locationSelect.dc.id}/62x30.png` : ''">
        <template v-if="portObj.diversityZone"
          #extraContent>
          <div>
            <span :title="$t(`services.${portObj.diversityZone}-zone`)">{{ $t('services.diversity-zone') }}
              <i class="far fa-dot-circle"
                :class="`diversity-color-${portObj.diversityZone}`" /></span>
          </div>
        </template>
      </end-details-card>

      <price-box :monthly-rate="priceStore.monthlyRate"
        :currency="priceStore.currency" />

      <megaport-spinner v-if="isLoadingService"
        class="mt-6" />
      <div v-else
        class="content-panel"
        :class="currentTab.id === 'logs' || currentTab.id === 'usage' ? 'full-size' : ''">
        <!-- Tab: Configuration -->
        <div v-if="currentTab.id === 'configuration'"
          v-loading="savingPort">
          <el-form ref="portForm"
            :model="portForm"
            :rules="portRules"
            label-width="200px"
            :disabled="!editable"
            @submit.native.prevent>
            <!-- Card Title: Port/MCR Configuration -->
            <h3 class="text-align-center mb-3">
              {{ $t('general.type-configuration', { product: $t(isMcr ? 'productNames.mcr' : 'productNames.port') }) }}
            </h3>

            <template v-if="isMcr">
              <el-tabs :value="mcrConfigActiveTab"
                @tab-click="handleMcrConfigTabClick">
                <el-tab-pane :label="$t('general.details')"
                  :name="DETAILS">
                  <div class="mt-1 mb-0 pt-0-5">
                    <edit-port-details :port-obj="portObj"
                      :deals="deals"
                      :speed-fix="speedFix"
                      :form="portForm"
                      :editable="editable"
                      :disabled-features="disabledFeatures"
                      @update="handleEditMegaportFormUpdate" />
                  </div>
                </el-tab-pane>

                <!-- Prefix List Editor -->
                <el-tab-pane :name="PREFIX_LISTS">
                  <template #label>
                    {{ $t('ports.prefix-filter-list') }}
                  </template>

                  <div role="tabpanel"
                    class="m-1 mb-0 pt-0-5">
                    <mu-prefix-list-editor :key="keys.prefixListEditor"
                      :prefix-lists="mcrPrefixLists.lists"
                      :edit-address-family="!mcrPrefixLists.saved"
                      :selected-prefix-list="mcrPrefixLists.selectedList"
                      :readonly="!editable"
                      @select="handlePrefixListSelection"
                      @update="handleMcrPrefixListUpdate"
                      @action="handleMcrPrefixListAction" />
                  </div>
                </el-tab-pane>
                <!-- END -->
              </el-tabs>
            </template>

            <template v-else>
              <edit-port-details :port-obj="portObj"
                :deals="deals"
                :speed-fix="speedFix"
                :form="portForm"
                :editable="editable"
                :disabled-features="disabledFeatures"
                @update="handleEditMegaportFormUpdate" />
            </template>

            <!-- Resource Tags -->
            <resource-tags :resource-tags="productResourceTags"
              :product-uid="portObj.productUid" />
            
            <!-- Save & Revert Buttons -->
            <div v-if="editable"
              class="text-align-right my-1">
              <!-- Revert Buttons -->
              <el-button :disabled="!isDirty"
                data-name="revert-port"
                @click="initForm">
                {{ $t('general.revert') }}
              </el-button>
              <!-- Save Buttons -->
              <el-button :disabled="!isDirty"
                data-name="save-port"
                type="primary"
                @click="savePort">
                {{ $t('general.save') }}
              </el-button>
              <hr class="mt-1">
            </div>
            <!-- END -->
          </el-form>
        </div>

        <!-- Tab: Details -->
        <div v-else-if="currentTab.id === 'advanced'"
          v-loading="!portObj.resources"
          class="detail-table mb-2">
          <!-- Card Title: Port/MCR Details -->
          <h3 class="text-align-center mb-3">
            {{ $t('general.type-details', { type: $t(isMcr ? 'productNames.mcr' : 'productNames.port') }) }}
          </h3>

          <!-- Sub-title: General -->
          <h4 class="mt-2 mb-1 text-align-center font-weight-500">
            {{ $t('general.general') }}
          </h4>

          <!-- Service ID -->
          <simple-read-only-field :label="$t('services.service-id')"
            :value="portObj._subUid"
            data-testid="service-id" />

          <!-- Provisioning Status -->
          <simple-read-only-field :label="$t('services.provisioning-status')"
            :value="portObj.provisioningStatus"
            data-testid="provisioning-status" />

          <!-- Service Status -->
          <simple-read-only-field :label="$t('services.service-status')"
            data-testid="service-status">
            <el-tooltip placement="top"
              :content="statusInfo.description"
              :open-delay="500">
              <div class="service-status-icon mt-0-25"
                :class="statusInfo.status" />
            </el-tooltip>
          </simple-read-only-field>

          <!-- MCR-ASN (MCR) -->
          <simple-read-only-field v-if="isMcr"
            :label="$t('ports.mcr-asn')"
            :value="mcrAsn"
            data-testid="mcr-asn" />

          <!-- Initial BGP State (MCR) -->
          <simple-read-only-field v-if="isMcr"
            :label="$t('services.initial-bgp-state')"
            :value="bgpShutdownDefault ? $t('general.shut-down') : $t('general.enabled')"
            data-testid="mcr-initial-bgp-state" />

          <!-- LACP Enabled (Non-MCR) -->
          <simple-read-only-field v-if="!isMcr"
            :label="$t('ports.lacp-enabled')"
            :value="portObj.aggregationId ? $t('general.yes') : $t('general.no')"
            data-testid="lacp-enabled" />

          <!-- Created By -->
          <simple-read-only-field v-if="createdByUser"
            :label="$t('general.created-by')"
            data-testid="created-by">
            <el-tooltip placement="top"
              :content="createdByUser.email"
              :open-delay="500">
              <div>
                {{ createdByUser.name }}
              </div>
            </el-tooltip>
          </simple-read-only-field>

          <!-- Media (Non-New & Non-MCR Service) -->
          <simple-read-only-field v-if="!isMcr && portObj.provisioningStatus !== G_PROVISIONING_NEW"
            :label="$t('services.media')"
            :value="interfaceMedia"
            data-testid="service-media" />

          <!-- Speed -->
          <simple-read-only-field :label="$t('services.speed')"
            :value="speedFix(portObj.portSpeed)"
            data-testid="service-speed" />

          <!-- Allocated -->
          <simple-read-only-field :label="$t('services.allocated')"
            data-testid="service-allocated">
            {{ speedFix(portObj._capacity.allocated) }}/{{ speedFix(portObj.portSpeed) }} ({{ portObj._capacity.percent }}%)
            <el-tooltip placement="top"
              :content="$t('ports.allocated-tooltip')"
              :open-delay="500"
              popper-class="max-width-none">
              <i class="fas fa-question-circle color-info popover-info-icon"
                aria-hidden="true" />
            </el-tooltip>
          </simple-read-only-field>

          <template v-if="portObj.resources && portObj.resources.interface && portObj.resources.interface.demarcation">
            <h4 class="mt-2 mb-1 text-align-center font-weight-500">
              {{ $t('services.demarcation') }}
            </h4>

            <el-tag type="info"
              class="color-text-regular mx-2 white-space-pre-line height-fit">
              {{ portObj.resources.interface.demarcation }}
            </el-tag>
          </template>
        </div>

        <template v-else-if="currentTab.id === 'logs'">
          <h3 class="text-align-center mt-1 mx-0 mb-2">
            {{ $t('services.service-logs') }}
          </h3>
          <service-logs :service-id="portObj.productUid" />
        </template>

        <template v-else-if="currentTab.id === 'usage'">
          <service-graph :service="portObj"
            :max="portObj.portSpeed" />
        </template>

        <div class="text-align-right mt-2">
          <el-button data-name="close-button"
            @click="handleCloseBtn">
            {{ $t('general.close') }}
          </el-button>
          <el-button v-if="[G_PROVISIONING_CONFIGURED, G_PROVISIONING_LIVE].includes(portObj.provisioningStatus) && !isMcr && !disabledFeatures.downloadLoa"
            data-name="download-loa"
            @click.stop="downloadLoa(portObj.productUid)">
            <i class="fas fa-file-download"
              aria-hidden="true" /> {{ $t('ports.download-loa') }}
          </el-button>
          <el-button v-if="tabIdx > 0"
            type="primary"
            plain
            class="step-button"
            @click="handleBackBtn">
            <i class="fas fa-arrow-circle-left"
              aria-hidden="true" /> {{ $t('general.back') }}
          </el-button>
          <el-button v-if="!finalTab"
            type="primary"
            plain
            class="step-button"
            @click="handleNextBtn">
            {{ $t('general.next') }}
            <i class="fas fa-arrow-circle-right"
              aria-hidden="true" />
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// External Tools
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { pick, get, isEmpty } from 'lodash'
import stableStringify from 'fast-json-stable-stringify'
// Internal Tools
import sdk from '@megaport/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'
import { convertSpeed, moneyFilter } from '@/helpers.js'
import { downloadFile } from '@/utils/downloadFile.js'
import { scopedPriceBook } from '@/utils/priceBook.js'

// Components
import EndDetailsCard from '@/components/ui-components/EndDetailsCard.vue'
import ServiceLogsComponent from '@/components/ServiceLogs.vue'
import ServiceGraphComponent from '@/components/graphs/ServiceGraph.vue'
import InfoBox from '@/components/ui-components/InfoBox.vue'
import PriceBox from '@/components/ui-components/PriceBox.vue'
import EditPortDetails from '@/components/ui-components/EditPortDetails.vue'
import SimpleReadOnlyField from '@/components/ui-components/SimpleReadOnlyField.vue'
import MegaportSpinner from '@/components/ui-components/MegaportSpinner.vue'
import ResourceTags from '@/components/ui-components/ResourceTags.vue'
// Globals
import { MEGAPORT_ASN } from '@/Globals.js'
// route history
import { history } from '@/routeGuards'

// actions
const NEW = 'new'
const DUPLICATE = 'duplicate'
const DELETE = 'delete'
const UPDATE = 'update'

// tabs
const DETAILS = 'details'
const PREFIX_LISTS = 'prefixLists'

export default {
  name: 'EditMegaport',

  components: {
    'service-logs': ServiceLogsComponent,
    'service-graph': ServiceGraphComponent,
    'edit-port-details': EditPortDetails,
    'end-details-card': EndDetailsCard,
    'info-box': InfoBox,
    'price-box': PriceBox,
    'simple-read-only-field': SimpleReadOnlyField,
    'megaport-spinner': MegaportSpinner,
    'resource-tags': ResourceTags,
  },

  filters: {
    formatInCurrency: moneyFilter,
  },

  inject: ['layout', 'disabledFeatures', 'isFeatureEnabled'],

  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      // Dynamically set the page title for the EditMegaport.vue component based on portObj status.
      // This has to be done here as the portObj is needed to tell if it is a port or MCR being created/edited
      document.title = `${vm.$t('companyInfo.portal')} - ${vm.$t(vm.isMcr ? 'page-titles.edit-mcr' : 'page-titles.edit-port')}`
    })
  },

  data() {
    return {
      // Constants
      dataRefreshInterval: 60 * 1000, // How often we should refresh the data (ms)

      tabIdx: 0,
      fetchDataInterval: null,
      priceStore: false,
      keys: {
        prefixListEditor: 0,
      },
      savingPort: false,
      portForm: {
        productName: null,
        rateLimit: null,
        costCentre: '',
        dealUid: null,
        bgpShutdownDefault: false,
        mcrAsn: null,
      },
      portRules: {
        productName: [
          { required: true, message: this.$t('validations.required', { thing: this.$t('services.product-name') }), trigger: 'blur' },
          { min: 0, max: 170, message: this.$tc('validations.max-length', 170, { max: 170 }) },
        ],
        costCentre: { min: 0, max: 255, message: this.$tc('validations.max-length', 255, { max: 255 }) },
        rateLimit: { required: true },
      },
      createdByUser: null,
      mcrConfigActiveTab: DETAILS,
      mcrPrefixLists: {
        lists: [],
        selectedList: null,
        isValid: false,
        action: null,
        payload: null,
        saved: false,
        isDirty: false,
      },
      MEGAPORT_ASN,
      DETAILS,
      PREFIX_LISTS,
      deals: [],
      allTabs: [
        {
          id: 'configuration',
          title: 'Configuration',
          icon: 'el-icon-edit',
        },
        {
          id: 'advanced',
          title: 'Details',
          icon: 'el-icon-view',
        },
        {
          id: 'logs',
          title: 'Logs',
          icon: 'el-icon-view',
        },
        {
          id: 'usage',
          title: 'Usage',
          icon: 'el-icon-view',
        },
      ],
      resourceTags: [],
    }
  },

  computed: {
    ...mapState('Users', ['users']),
    ...mapState('Services', ['locations', 'services']),
    ...mapGetters('Auth', ['isPartnerAccount', 'isManagedAccount', 'hasAuth']),
    ...mapGetters('Services', ['myPorts']),
    ...mapGetters('Company', ['companyUid']),
    ...mapGetters('ApplicationContext', [{ applicationContextIsLoading: 'companyContextLoading' }, 'companyContextLoading']),

    allowNotify() {
      return !this.isPartnerAccount && !this.companyContextLoading
    },
    showPricing() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
    isDirty() {
      if (this.mcrConfigActiveTab === PREFIX_LISTS) return this.mcrPrefixLists.isValid

      // Work with the existing costCentre value and if there isn't one, use the form's default value
      const existingCostCentre = this.portObj.costCentre || ''
      // Work with the existing dealUid value and if there isn't one, use the form's default value
      const existingDealUid = this.getDealIfApplicable(this.portObj.dealUid)

      const configurationTabValidation =
        this.portForm.productName !== this.portObj.productName ||
        this.portForm.dealUid !== existingDealUid ||
        this.portForm.rateLimit !== this.portObj.portSpeed ||
        this.portForm.costCentre !== existingCostCentre ||
        (this.portObj.productType === this.G_PRODUCT_TYPE_MCR2 && this.portForm.bgpShutdownDefault !== this.portObj.resources?.virtual_router.bgpShutdownDefault)

      return configurationTabValidation
    },
    interfaceMedia() {
      return this.portObj.resources?.interface?.media
    },
    statusInfo() {
      const status = {
        description: this.$t('general.status-unknown'),
        status: 'partial-success',
      }

      if (isEmpty(this.portObj)) return status

      switch (this.portObj.up) {
        case true:
          status.description = this.$t('services.up')
          status.status = 'all-go'
          break
        case false:
          status.description = `${this.$t('services.down')} ${this.$t('services.check-logs')}`
          status.status = 'all-fail'
      }

      return status
    },
    portObj() {
      const serviceId = this.$route.params.serviceId

      if (serviceId?.length === 8) {
        return this.myPorts.find(port => port.productUid.startsWith(serviceId)) || {}
      } else {
        return this.myPorts.find(port => port.productUid === serviceId) || {}
      }
    },
    mcrAsn() {
      // The productUid will not be there while loading, but is guaranteed to be there once loaded.
      // Note that we can't test for equality with {} since it's an observed object.
      return this.portObj?.resources?.virtual_router?.mcrAsn || MEGAPORT_ASN
    },
    mcrPrefixListsRequest() {
      return sdk.instance.mcrPrefixLists(this.portObj.productUid)
    },
    bgpShutdownDefault() {
      return this.portObj?.resources?.virtual_router?.bgpShutdownDefault || false
    },
    currentTab() {
      return this.allTabs[this.tabIdx]
    },
    finalTab() {
      return this.allTabs.length === this.tabIdx + 1
    },
    editable() {
      return this.hasAuth('modify_service') && !this.portObj.locked && !this.portObj.adminLocked
    },
    nonEditableReason() {
      if (this.editable) return null

      if (this.portObj.adminLocked) {
        return 'services.service-locked'
      } else if (this.portObj.locked) {
        return 'services.service-admin-locked'
      } else {
        return 'services.edit-permission-denied'
      }
    },
    locationSelect() {
      return this.locations.find(location => location.id === this.portObj.locationId) || null
    },
    detailLines() {
      const detailLines = []

      detailLines.push(this.portForm.productName || this.$t('general.untitled'))
      detailLines.push(this.speedFix(this.portForm.rateLimit))
      detailLines.push(this.locationSelect ? this.locationSelect.formatted.short : this.$t('general.unknown-location'))

      return detailLines
    },
    isMcr() {
      return this.portObj.productType === this.G_PRODUCT_TYPE_MCR2
    },
    isLoadingService() {
      return this.portObj.provisioningStatus === this.G_PROVISIONING_LOADING
    },
    productResourceTags() {
      return this.resourceTags
    },
  },

  watch: {
    'portForm.rateLimit'() {
      if (this.applicationContextIsLoading) return

      if (this.showPricing) this.getPrice()
    },
    'portObj.contractEndDate'(date) {
      if (this.applicationContextIsLoading || date === undefined) return

      sdk.instance.resetPriceBookCache()

      if (this.showPricing) this.getPrice()
    },
    services: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        const oldPort = oldValue ? oldValue.find(port => port.productUid.startsWith(this.$route.params.serviceId)) : null
        const newPort = newValue ? newValue.find(port => port.productUid.startsWith(this.$route.params.serviceId)) : null

        if (oldPort && !newPort) {
          const props = {
            title: this.$t('ports.port-deleted'),
            message: this.$t('ports.port-removed'),
            type: 'error',
            duration: 3000,
          }

          this.allowNotify && this.$notify(props)

          this.$router.push(resolveServicesPage())
        } else if (!oldPort && newPort) {
          // This could happen if the user reloaded and when the page loaded the services weren't there
          this.initForm()
        } else if (oldPort && newPort) {
          // The data has been edited outside of us. See if any form values have been changed
          const matchingFields = ['productName', 'portSpeed', 'costCentre']

          const oldFields = pick(oldPort, matchingFields)
          const newFields = pick(newPort, matchingFields)

          if (!this.portForm.productName || stableStringify(oldFields) !== stableStringify(newFields)) {
            this.initForm()
          }
        }
      },
    },
  },

  beforeDestroy() {
    this.setPeriodicRefreshEnabled(true)
  },

  mounted() {
    // Don't refresh the data while editing!
    this.setPeriodicRefreshEnabled(false)
  },

  created() {
    // Refresh the data for the service when we initially load
    this.fetchService()
  },

  methods: {
    ...mapMutations('Notifications', ['notifyGood', 'notifyBad']),
    ...mapActions('RouteFiltering', ['getPrefixLists']),
    ...mapActions('Services', ['editService', 'fetchServiceOrConnection', 'setPeriodicRefreshEnabled']),
    scopedPriceBook,

    async fetchService() {
      await this.fetchServiceOrConnection({
        productUid: this.portObj.productUid || this.$route.params.serviceId,
        incResources: true,
      })
      this.fetchDeals()
    },

    async downloadLoa(productUid) {
      this.savingPort = true
      const url = `${sdk.instance.baseurl}/v2/product/${productUid}/loa`

      try {
        await downloadFile(url, { openInNewTab: true })
      } finally {
        this.savingPort = false
      }
    },

    getDealIfApplicable(dealUid = '') {
      if (!dealUid || !this.deals.length) return 'None'

      const existingDeal = this.deals.find(deal => deal.entityUid === dealUid)

      return existingDeal ? existingDeal.entityUid : 'None'
    },
    initForm() {
      this.portForm = {
        productName: this.portObj.productName || null,
        rateLimit: this.portObj.portSpeed || null,
        dealUid: this.getDealIfApplicable(this.portObj.dealUid),
        costCentre: this.portObj.costCentre || '',
        bgpShutdownDefault: this.portObj.resources?.virtual_router?.bgpShutdownDefault || false,
      }

      this.createdByUser = null

      if (this.portObj.createdBy) {
        sdk.instance
          .employment()
          .get()
          .then(data => {
            this.createdByUser = data.find(user => user.personUid === this.portObj.createdBy)
          })
          .catch(e => {
            captureSentryError(e)
          })
      }

      if (this.mcrConfigActiveTab === PREFIX_LISTS) {
        this.keys.prefixListEditor++
      }

      // prefix list fields are pristine
      this.mcrPrefixLists.isDirty = false
      this.savingPort = false
    },
    fetchDeals() {
      if (!this.isManagedAccount) {
        this.savingPort = false

        return
      }

      const companyUid = this.$store.state.Company.data.companyUid || ''

      let defaultDeal = {
        entityUid: 'None',
        dealId: '',
        opportunityName: 'None',
      }

      sdk.instance
        .partnerVantage()
        .deals(companyUid, { includeInvalidDeals: true })
        .then(deals => {
          if (deals.length) {
            // If the dealUid does not match the entityUid of another deal, it is a standard deal.
            const isStandardDeal = !deals.find(deal => deal.entityUid === this.portObj.dealUid)

            if (isStandardDeal) {
              defaultDeal.entityUid = this.portObj.dealUid
            }

            this.deals = [defaultDeal, ...deals]

            // Sort in ascending order by deal ID
            this.deals = this.deals.sort((a, b) => a.dealId.toUpperCase().localeCompare(b.dealId.toUpperCase()))
          } else {
            this.deals = [defaultDeal]
          }
        })
        .catch(e => e)
        .finally(() => {
          this.initForm()
          this.savingPort = false
        })
    },
    speedFix(val) {
      return convertSpeed(val) // Defined globally on window
    },
    getPrice() {
      this.priceStore = false

      if (this.portForm.rateLimit) {
        if (this.isMcr) {
          this.scopedPriceBook()
            .mcr(this.portObj.locationId, this.portForm.rateLimit, this.portObj.productUid)
            .then(res => {
              this.priceStore = res
            })
            .catch(() => {
              // Error handled by the pricebook module.
              this.priceStore = false
            })
        } else {
          this.scopedPriceBook()
            .megaport(
              this.portObj.locationId,
              this.portForm.rateLimit,
              this.portObj.contractTermMonths,
              this.portObj.buyoutPort,
              this.portObj.productUid
            )
            .then(res => {
              this.priceStore = res
            })
            .catch(() => {
              // Error handled by the pricebook module.
              this.priceStore = false
            })
        }
      }
    },
    getNotifications(type) {
      const { action } = this.mcrPrefixLists
      let word = ''

      // get the action messages
      switch (action) {
        case NEW:
        case DUPLICATE:
          word = 'creating'
          break

        case UPDATE:
          word = 'updating'
          break

        case DELETE:
          word = 'deleting'
          break
      }

      // return the notifications messages as per type
      return this.$t(`${type}-${word}`, { thing: this.$t('ports.prefix-list-label') })
    },
    savePrefixLists(isNewList) {
      this.savingPort = true

      this.handleMcrPrefixListsActionPromise()
        .then(async res => {
          this.notifyGood({
            title: this.getNotifications('success'),
          })

          await this.loadMcrPrefixLists()

          if (isNewList) {
            this.mcrPrefixLists = {
              ...this.mcrPrefixLists,
              saved: true,
              selectedList: this.computeSelectedPrefixListData(res),
              id: res.id,
            }
          }
        })
        .catch(err => {
          this.$notify({
            title: this.getNotifications('error'),
            message: err.data.message,
            type: 'error',
          })

          if (isNewList) this.mcrPrefixLists.saved = false
        })
        .finally(() => {
          this.savingPort = false
          this.mcrPrefixLists.isDirty = false
        })
    },
    savePort() {
      if (!this.editable) return

      if (this.mcrConfigActiveTab === PREFIX_LISTS) return this.savePrefixLists(true)

      this.$refs.portForm.validate(async valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }

          this.$notify(props)

          return
        }

        this.savingPort = true

        const service = { ...this.portObj, ...this.portForm }
        await this.editService({ service })

        if (!this.portObj.aggregationId) this.savingPort = false

        // If it's part of a LAG and they have changed the public/private setting,
        // we need to propagate that to all the ports in the LAG.
        if (this.portObj.aggregationId) {
          for (const port of this.services) {
            if (port.aggregationId === this.portObj.aggregationId && port.productUid !== this.portObj.productUid) {
              const lagPortPayload = { ...port }

              await this.editService({ service: lagPortPayload })
            }
          }
          this.savingPort = false
        }
      })
    },
    async loadMcrPrefixLists() {
      this.savingPort = true

      await this.getPrefixLists({ productUid: this.portObj.productUid })

      this.savingPort = false

      const { prefixLists = [] } = this.$store.state.RouteFiltering

      this.mcrPrefixLists = { ...this.mcrPrefixLists, lists: prefixLists }

      return Promise.resolve()
    },
    handleMcrRateLimitUpdate({ value, valid }) {
      if (valid) this.portForm.rateLimit = value
    },
    handleMcrAsnUpdate({ value, valid }) {
      if (valid) this.portForm.mcrAsn = value
    },
    handleMcrPrefixListUpdate({ action, payload, isValid }) {
      this.mcrPrefixLists.isValid = isValid
      this.mcrPrefixLists.action = action
      this.mcrPrefixLists.payload = payload

      // set this to true to signal that the prefix list fields are not pristine
      this.mcrPrefixLists.isDirty = true

      // added this as we don't need to click on save button again once we
      // confirm the delete operation in the confirmation modal
      if (action === DELETE && !!payload.id) this.savePrefixLists(false)
    },
    handleMcrPrefixListsActionPromise() {
      const { action, payload } = this.mcrPrefixLists
      const { id, description = null, addressFamily = null, entries = null } = payload

      switch (action) {
        case NEW:
        case DUPLICATE:
          return this.mcrPrefixListsRequest.create(payload)
        case UPDATE:
          return this.mcrPrefixListsRequest.update(id, { description, addressFamily, entries })
        case DELETE:
          return this.mcrPrefixListsRequest.delete(id)
      }
    },
    handlePrefixListSelection(id) {
      if (this.mcrPrefixLists.isDirty) return this.savePrefixListConfirm()

      this.savingPort = true

      this.mcrPrefixListsRequest
        .get(id)
        .then(res => {
          this.mcrPrefixLists.selectedList = this.computeSelectedPrefixListData(res)

          this.notifyGood({ title: this.$t('ports.prefix-list-loaded-success') })
        })
        .catch(err => {
          this.notifyBad({
            title: this.$t('ports.prefix-list-loaded-fail'),
            message: get(err, 'data.message'),
          })
        })
        .finally(() => {
          this.savingPort = false
        })
    },
    handleEditMegaportFormUpdate({ key, value }) {
      this.portForm = { ...this.portForm, [key]: value }
    },
    handleMcrPrefixListAction() {
      this.mcrPrefixLists.saved = false
    },
    handleMcrConfigTabClick({ name }) {
      this.mcrConfigActiveTab = name

      if (name === PREFIX_LISTS) this.loadMcrPrefixLists()
    },
    getNetworkRange(rule) {
      const { prefix } = rule
      const [, cidr = 0] = prefix.split('/')

      return Number(cidr) || 0
    },
    computeSelectedPrefixListData(list) {
      // to handle conditions where le or ge keys in rules are not returned from api
      const computedRules = rules => {
        return rules.map(rule => {
          const range = this.getNetworkRange(rule)

          // no le & ge returned from api
          if (!rule.ge && !rule.le) {
            return {
              ...rule,
              exact: true,
              ge: range,
              le: range,
            }
          }

          // no ge returned from api
          if (!rule.ge && rule.le) {
            return {
              ...rule,
              ge: range,
            }
          }

          // no le returned from api
          if (!rule.le && rule.ge) {
            return {
              ...rule,
              le: this.selectedList.addressFamily === 'IPv4' ? 32 : 128,
            }
          }

          return rule
        })
      }

      return {
        ...list,
        entries: computedRules(list.entries),
      }
    },
    savePrefixListConfirm() {
      this.$confirm(this.$t('ports.prefix-list-save-confirm'), 'warning', {
        confirmButtonText: this.$t('general.save'),
        cancelButtonText: this.$t('general.revert'),
        type: 'warning',
        distinguishCancelAndClose: true,
      })
        .then(() => {
          this.savePort()
        })
        .catch(action => {
          action === 'cancel' && this.initForm()
        })
    },
    handleBackBtn() {
      if (this.mcrPrefixLists.isDirty) return this.savePrefixListConfirm()

      if (this.tabIdx <= 0) return this.$router.push(resolveServicesPage())

      this.tabIdx--
    },
    handleNextBtn() {
      if (this.mcrPrefixLists.isDirty) return this.savePrefixListConfirm()

      this.tabIdx++
    },
    handleCloseBtn() {
      if (this.mcrPrefixLists.isDirty) return this.savePrefixListConfirm()

      if (history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push(resolveServicesPage())
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.contractTable {
  td,
  th {
    color: var(--color-text-regular);
    padding: 0.5rem 1.5rem 0.5rem 0;
    text-align: left;
  }
}

.header {
  width: 100%;
  padding-top: 1rem;
  background-color: var(--mp-sub-header-background-color);
  border-bottom: 1px solid var(--card-border-color);
}

.steps {
  padding: 1rem;
}

.non-editable {
  background-color: var(--color-white);
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 2rem;
  text-align: center;
  width: fit-content;
  margin: auto;
  margin-bottom: 2rem;
  line-height: normal;
}

.port-summary {
  width: fit-content;
  margin-inline: auto;
}

svg.icon {
  display: inline-block;
  color: var(--color-text-regular);
  width: 2.5em;
  height: 2.5em;
  fill: currentColor;
}

.content-panel {
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 2rem;
  margin: auto;
  width: fit-content;
  min-width: 700px;
  &.full-size {
    width: unset;
  }
}

.detail-table .el-row {
  color: var(--color-text-regular);
  font-weight: 300;
  min-width: 400px;
  .el-col:first-of-type {
    font-weight: 500;
    text-align: right;
  }
  &.total {
    border-top: 1px solid var(--card-border-color);
    border-bottom: 1px solid var(--card-border-color);
  }
}

.max-width-500px {
  max-width: 500px;
}
</style>

<style lang="scss">
button.el-button.el-button--primary.is-plain.step-button {
  &:focus {
    background-color: var(--color-primary-light-9);
    color: var(--color-primary);
    border-color: var(--color-primary-light-6);
    outline: none;
  }
}

.port-details .el-step__icon.is-icon {
  background-color: var(--mp-sub-header-background-color);
}
</style>
